import { MDCRipple } from '@material/ripple';
import { MDCDialog } from '@material/dialog';
import { MDCTextField } from '@material/textfield';

const androidRegisterTitle = "Register For Beta - Android";
const iosRegisterTitle = "Register For Beta - iOS";
const androidRegisterDesc = "Enter your name and Play Store email below and we'll send you the registration link to download the app.";
const iosRegisterDesc = "Enter your name and Apple ID below and we'll send you the registration link to download through TestFlight.";
const emailRegex = /^[\w+\-.]+@[a-z\d\-.]+\.[a-z]+$/

const iosConfirmationTitle = "Registration link sent";
const iosConfirmationDesc = "Check your inbox and open an email from Testflight for instructions to download the app.";
const androidConfirmationTitle = "Registration complete";
const androidConfirmationDesc = "Thanks for registering! An invitation email will be sent later, please keep an eye on your inbox.";

$(document).ready(() => {
    $.ajaxSetup({
        headers: {
            'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
        }
    });
    $(".mdc-button").each(function () {
        MDCRipple.attachTo(this);
    });
    $(".mdc-icon-button").each(function () {
        const iconButtonRipple = new MDCRipple(this);
        iconButtonRipple.unbounded = true;
    });

    let nameField = new MDCTextField($("#name-field")[0]);
    let emailField = new MDCTextField($("#email-field")[0]);

    let registerDialog = new MDCDialog($("#register-dialog")[0]);
    let confirmationDialog = new MDCDialog($("#confirmation-dialog")[0]);
    let errorDialog = new MDCDialog($("#error-dialog")[0]);

    registerDialog.listen('MDCDialog:opening', (e) => {
        nameField.valid = true
        emailField.valid = true
        nameField.helperTextContent = ""
        emailField.helperTextContent = ""
    });

    registerDialog.listen('MDCDialog:closed', (e) => {
        // reset fields
        nameField.value = ""
        emailField.value = ""
    });

    $("#register-dialog .mdc-button--submit").on("click", function (e) {
        e.preventDefault();
        e.stopPropagation();

        if (!nameField.valid || !emailField.valid) {
            return;
        }

        const submitBtn = $(this);
        const loadingIndicator = $("#register-dialog .mdc-dialog__loading");
        const osData = $("#register-dialog").attr("os-data");
        const name = nameField.value;
        const email = emailField.value;

        if (osData !== "ios" && osData !== "android") return

        loadingIndicator.removeClass("mdc-dialog__loading--hidden");
        submitBtn.addClass("mdc-button--hidden");
        nameField.disabled = true;
        emailField.disabled = true;

        $.post("/api/testers", { name: name, email: email, os: osData }, () => {
            if (osData === "ios") {
                $("#confirmation-dialog .mdc-dialog__title").text(iosConfirmationTitle);
                $("#confirmation-dialog .mdc-dialog__content").text(iosConfirmationDesc);
            } else if (osData === "android") {
                $("#confirmation-dialog .mdc-dialog__title").text(androidConfirmationTitle);
                $("#confirmation-dialog .mdc-dialog__content").text(androidConfirmationDesc);
            }

            fbq('track', 'Lead', {content_category: osData, content_name: email});

            confirmationDialog.open();
            registerDialog.close();
            loadingIndicator.addClass("mdc-dialog__loading--hidden");
            submitBtn.removeClass("mdc-button--hidden");
            nameField.disabled = false;
            emailField.disabled = false;
        }).fail(function () {
            errorDialog.open();
            registerDialog.close();
            loadingIndicator.addClass("mdc-dialog__loading--hidden");
            submitBtn.removeClass("mdc-button--hidden");
            nameField.disabled = false;
            emailField.disabled = false;
        })
    });

    $("#name-field input").on("focus", () => {
        nameField.helperTextContent = ""
    })

    $("#email-field input").on("focus", () => {
        emailField.helperTextContent = ""
        emailField.valid = true
    })

    $("#name-field input").on("focusout", () => {
        if (nameField.value.length < 3) {
            nameField.helperTextContent = "Name is too short."
        }
    })

    $("#email-field input").on("focusout", () => {
        if (!emailRegex.test(emailField.value)) {
            emailField.helperTextContent = "That's an invalid email."
            emailField.valid = false
        }
    })

    $("#register-dialog .mdc-dialog__close").on("click", function () {
        registerDialog.close()
    });

    $("#nav-menu__features").on("click", () => {
        firebase.analytics().logEvent('click_nav_features');
    });

    $("#nav-menu__download").on("click", () => {
        firebase.analytics().logEvent('click_nav_download');
    });

    $("#hero-btn").on("click", () => {
        firebase.analytics().logEvent('click_get_the_app');
    });

    $("#download-android-btn").on("click", () => {
        $("#register-dialog .mdc-dialog__title span").text(androidRegisterTitle);
        $("#register-dialog .mdc-dialog__content__description").text(androidRegisterDesc);
        $("#email-field__label").text("Play Store Email");
        $("#email-field input").attr("placeholder", "Play Store Email");
        $("#register-dialog").attr("os-data", "android");
        registerDialog.open();

        firebase.analytics().logEvent('download_android');
    });

    $("#download-ios-btn").on("click", () => {
        $("#register-dialog .mdc-dialog__title span").text(iosRegisterTitle);
        $("#register-dialog .mdc-dialog__content__description").text(iosRegisterDesc);
        $("#email-field__label").text("Apple ID");
        $("#email-field input").attr("placeholder", "Apple ID");
        $("#register-dialog").attr("os-data", "ios");
        registerDialog.open();

        firebase.analytics().logEvent('download_ios');
    });

    $("#send-mail-icon-btn").on("click", () => {
        firebase.analytics().logEvent('send_mail');
    });

    $("#ig-icon-btn").on("click", () => {
        firebase.analytics().logEvent('open_instagram_page');
    });

    $("#fb-icon-btn").on("click", () => {
        firebase.analytics().logEvent('open_facebook_page');
    });

    $("#yt-icon-btn").on("click", () => {
        firebase.analytics().logEvent('open_youtube_page');
    });

    // clean query parameters
    if (window.history != undefined && window.history.pushState != undefined) {
        window.history.pushState({}, document.title, window.location.pathname);
    }
});